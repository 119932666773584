import * as React from "react"
import { graphql } from "gatsby"

import { IContentNode } from "../types/Content"
import { ILocation } from "../types/Location"
import { ITopic } from "../types/Topic"

import Layout from "../components/layout"
import SEO from "../components/seo"

import ArticleTimeline, { makeArticleTimelineItem } from "../components/ArticleTimeline"
import PostHeader from "../components/PostHeader"

import "./styles/topic.scss"

interface IProps {
  location: ILocation
  data: {
    site: {
      siteMetadata: {
        topics: ITopic[]
      }
    }

    allTopicContent: {
      edges: [
        {
          node: {
            content: IContentNode
          }
        }
      ]
    }
  }
}
const TopicTemplate: React.FC<IProps> = ({ data, location }) => {
  const topic = data.site.siteMetadata.topics.filter((topic) => `/topic/${topic.title.toLowerCase()}` === location.pathname.toLowerCase())[0]!
  
  return (
    <Layout currentPath={location.pathname}>
      {({ theme }) => (
        <>
          <SEO title={topic.title} />

          <PostHeader
            description={topic.description}
            icon={topic.icon}
            theme={theme}
            title={topic.title}
          />

        <div className={`topic-articles--${theme}`}>
          <h1 className="topic-articles__header fs-32">📝 Articles</h1>

          <ArticleTimeline
            articles={data.allTopicContent.edges.map(({ node }) => makeArticleTimelineItem(node.content))}
            currentPath={location.pathname}
            theme={theme}
          />
        </div>
        </>
      )}
    </Layout>
  )
}

export const query = graphql`
  query($topic: String!) {
    site {
      siteMetadata {
        topics {
          icon
          title
          description
        }
      }
    }

    allTopicContent: allFile(
      filter: { childMdx: {frontmatter: {category: {eq: $topic}}}, sourceInstanceName: { in: ["articles"] } }
      sort: { fields: [childMdx___frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          content: childMdx {
            id
            body
            frontmatter {
              category
              date
              tags
              title
            }
            fields {
              slug
            }
          }
        }
      }
    }
  }
`

export default TopicTemplate
